<template>
  <div :class="$style.container" ref="stories">
    <div
      :class="$style.action"
      @mousedown="mouseDown"
      @mouseup="mouseUp"
      @touchstart="mouseDown"
      @touchend="mouseUp"
    ></div>
    <div :class="$style.content">
      <v-media
        :media="stories[index]"
        video-ready
        video-active
        :video-loop="false"
        is-autoplay
        :is-paused="paused"
        video-no-control
        @load="onLoad"
      />
    </div>
    <div v-show="isOverlayVisible" :class="$style.header" ref="header">
      <v-stories-timeline
        :current-index="index"
        :stories="stories"
        :is-paused="paused || isLoading"
        :is-loading="isLoading"
        :duration="duration"
        @storyStart="storyStart"
        @storyEnd="storyEnd"
      />
      <v-stories-header :story="story" @additional="isAdditionalVisible = true" />
    </div>
    <v-stories-menu
      :is-visible.sync="isAdditionalVisible"
      :id="story.id"
      @storyDelete="storyDelete"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import VMedia from '@layouts/VMedia.vue'
import VStoriesTimeline from './list/VStoriesTimeline.vue'
import VStoriesHeader from './list/VStoriesHeader.vue'
import VStoriesMenu from './list/VStoriesMenu.vue'

export default Vue.extend({
  name: 'VStories',
  components: {
    VStoriesMenu,
    VMedia,
    VStoriesHeader,
    VStoriesTimeline,
  },
  props: {
    story: Object,
    interval: {
      type: Number,
      default: 15000,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    fromEnd: Boolean,
    isPaused: Boolean,
  },
  data() {
    return {
      index: this.currentIndex,
      paused: false,
      isLoading: true,
      mouseDownTimeout: undefined,
      isOverlayVisible: true,
      isAdditionalVisible: false,
    }
  },
  computed: {
    stories() {
      return this.story?.media_objects ?? null
    },
    lastIndex() {
      return (this.stories?.length ?? 1) - 1
    },
    duration() {
      return this.stories?.[this.index].duration ?? this.interval
    },
  },
  watch: {
    currentIndex: {
      handler(val) {
        this.index = val
        this.isLoading = true
      },
    },
    fromEnd: {
      handler(val) {
        if (val) {
          this.index = this.lastIndex
        }
      },
      immediate: true,
    },
    isPaused: {
      immediate: true,
      handler(val) {
        this.paused = val
      },
    },
    paused(val) {
      if (val) {
        this.pause()
      } else {
        this.play()
      }
      this.$emit('update:isPaused', val)
    },
    index() {
      this.isLoading = true
    },
  },
  methods: {
    getX(e) {
      if (e instanceof MouseEvent) {
        return e.offsetX
      }
      const touch = e.touches[0] ?? e.changedTouches[0]
      return touch.clientX
    },
    mouseDown(e) {
      e.preventDefault()
      this.mouseDownTimeout = setTimeout(() => this.togglePause(), 500)
    },
    mouseUp(e) {
      e.preventDefault()
      if (this.mouseDownTimeout) {
        clearTimeout(this.mouseDownTimeout)
      }
      if (this.paused) {
        this.paused = false
      } else {
        const x = this.getX(e)
        const { width } = this.$refs.stories.getBoundingClientRect()
        const t = width / 3
        if (x > t) {
          this.nextSlide()
        } else {
          this.previousSlide()
        }
      }
    },
    previousSlide() {
      if (this.index > 0) {
        this.index--
      } else {
        this.index = 0
        this.$emit('previousStory')
      }
    },
    nextSlide() {
      if (this.index < this.stories.length - 1) {
        this.index++
      } else {
        this.index = 0
        this.$emit('nextStory')
      }
    },
    togglePause() {
      this.paused = !this.paused
    },
    pause() {
      this.isOverlayVisible = false
    },
    play() {
      this.isOverlayVisible = true
    },
    storyStart(index) {
      this.$emit('storyStart', index)
      this.$emit('update:currentIndex', index)
    },
    storyEnd(index) {
      this.nextSlide()
      this.$emit('storyEnd', index)
    },
    storyDelete() {
      this.$router.push({ name: 'Profile' })
    },
    onLoad() {
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $secondary;
}

.action {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: grid;
  grid-template-rows: 15px 55px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}
</style>
