// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VStories_container_3nIr9{position:relative}.VStories_container_3nIr9,.VStories_content_3A5ms{width:100%;height:100%}.VStories_content_3A5ms{display:flex;align-items:center;justify-content:center;background-color:var(--supster-secondary)}.VStories_action_zfmgs{z-index:1;height:100%}.VStories_action_zfmgs,.VStories_header_1YXxv{position:absolute;top:0;left:0;width:100%}.VStories_header_1YXxv{z-index:2;display:grid;grid-template-rows:15px 55px;background:rgba(0,0,0,.3)}", ""]);
// Exports
exports.locals = {
	"container": "VStories_container_3nIr9",
	"content": "VStories_content_3A5ms",
	"action": "VStories_action_zfmgs",
	"header": "VStories_header_1YXxv"
};
module.exports = exports;
