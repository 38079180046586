<template>
  <ListModal
    v-if="isVisible"
    :is-visible.sync="isMenuVisible"
    :list="menuList"
    @update:is-visible="$emit('update:is-visible', $event)"
    @scrollbar="onShowScrollBar"
    @click="onMenu"
  />
  <ConfirmModal
    v-else
    :is-visible.sync="isConfirmVisible"
    :title="$t('confirm.delete.stories')"
    @confirm="onConfirmDelete"
    @close="onCloseConfirm"
  />
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import ListModal from '@modals/list/ListModal.vue'
import ConfirmModal from '@modals/list/ConfirmModal.vue'

export default Vue.extend({
  name: 'VStoriesMenu',
  components: { ListModal, ConfirmModal },
  props: {
    isVisible: Boolean,
    id: Number,
  },
  data() {
    return {
      isConfirmVisible: false,
    }
  },
  computed: {
    ...mapGetters('Client', ['isClientOwner']),
    isMenuVisible: {
      get() {
        return this.isVisible
      },
      set(isVisible) {
        this.$emit('is-visible', isVisible)
      },
    },
    menuList() {
      return [{ name: 'delete', value: this.$t('post.delete'), visible: this.isClientOwner }]
    },
  },
  methods: {
    ...mapActions('Stories', ['deleteStory']),
    onShowScrollBar(isVisible) {
      this.$bus.$emit('scrollbar', { isVisible })
    },
    onMenu(link) {
      if (link.name === 'delete') {
        this.isMenuVisible = false
        this.isConfirmVisible = true
      }
    },
    async onConfirmDelete() {
      await this.deleteStory(this.id)
      this.isConfirmModalVisible = false
      this.$emit('storyDelete')
    },
    onCloseConfirm() {
      this.isConfirmModalVisible = false
    },
  },
})
</script>
