<template>
  <div :class="$style.timeline">
    <div v-for="(item, i) in stories.length" :key="i" :class="$style.item">
      <div :class="$style.progress" :style="`width: ${progress(i)}%`"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VStoriesTimeline',
  props: {
    stories: Array,
    currentIndex: Number,
    isPaused: Boolean,
    isLoading: Boolean,
    duration: Number,
  },
  data() {
    return {
      count: 0,
      startTime: 0,
      savedTimeStamp: 0,
      deltaPaused: 0,
      lastPaused: 0,
      animFrameId: -1,
      isClosed: false,
    }
  },
  computed: {
    currentStory() {
      return this.stories[this.currentIndex]
    },
    currentStoryId() {
      return this.currentStory?.id
    },
  },
  watch: {
    currentStoryId() {
      this.resetTimeline()
    },
    currentIndex() {
      this.resetTimeline()
    },
    isPaused(paused) {
      cancelAnimationFrame(this.animFrameId)
      if (this.isLoading) {
        return
      }
      if (paused) {
        this.lastPaused = performance.now()
      } else {
        if (this.lastPaused) {
          this.deltaPaused += performance.now() - this.lastPaused
        }
        this.animFrameId = requestAnimationFrame(this.incrementCount)
      }
    },
  },
  mounted() {
    if (!this.isLoading) {
      this.animFrameId = requestAnimationFrame(this.incrementCount)
    }
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animFrameId)
  },
  methods: {
    progress(i) {
      // eslint-disable-next-line no-nested-ternary
      return i === this.currentIndex ? this.count : i < this.currentIndex ? 100 : 0
    },
    resetTimeline() {
      this.count = 0
      this.startTime = 0
      this.deltaPaused = 0
      this.lastPaused = 0
      cancelAnimationFrame(this.animFrameId)
      this.storyStart()
      if (!this.isLoading) {
        this.animFrameId = requestAnimationFrame(this.incrementCount)
      }
    },
    storyStart() {
      this.$emit('storyStart', this.currentIndex)
    },
    storyEnd() {
      this.$emit('storyEnd', this.currentIndex)
    },
    allStoriesEnd() {
      this.$emit('allStoriesEnd', this.currentIndex)
    },
    incrementCount(timestamp) {
      if (this.isClosed) {
        return
      }
      if (!this.startTime) {
        this.startTime = timestamp
      }

      const runtime = timestamp - this.startTime - this.deltaPaused

      this.count = (runtime / this.duration) * 100

      if (this.count < 100) {
        this.animFrameId = requestAnimationFrame(this.incrementCount)
      } else {
        this.storyEnd()
        if (+this.currentIndex === this.stories.length - 1) {
          this.allStoriesEnd()
        }
        cancelAnimationFrame(this.animFrameId)
      }
    },
  },
})
</script>

<style lang="scss" module>
.timeline {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 3px;
  padding: 10px;
}

.item {
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

.progress {
  width: 0;
  height: 2px;
  background: white;
  border-radius: 2px;
}
</style>
