<template>
  <div :class="[$style.container, !isClientOwner && $style.noMenu]">
    <v-avatar
      :class="$style.avatar"
      :src="story.app ? story.app.image.content_url : null"
      size="xs"
      progress-color="white"
    />
    <span :class="$style.name">{{ story.app.name }}</span>
    <v-button
      v-if="isClientOwner"
      size="md"
      color="transparent"
      is-disabled-padding
      @click="onAdditional"
    >
      <icon name="dots-3" width="2" height="14" />
    </v-button>
    <v-button
      size="md"
      color="transparent"
      :class="$style.close"
      is-disabled-padding
      @click="onClose"
    >
      <icon name="close" width="14" height="14" />
    </v-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import VButton from '@elements/VButton.vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'

export default Vue.extend({
  name: 'VStoriesHeader',
  components: { VButton, VAvatar },
  props: { story: Object },
  computed: {
    ...mapGetters('Client', ['isClientOwner']),
  },
  methods: {
    onAdditional() {
      this.$emit('additional')
    },
    onClose() {
      this.$router.push({ name: 'Profile' })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-gap: 9px;
  grid-template-columns: 36px 1fr 36px 36px;
  align-items: center;
  padding: 0 12px;
  color: white;

  &.noMenu {
    grid-template-columns: 36px 1fr 36px;
  }
}

.name {
  font-weight: 500;
  font-size: 15px;
}

.views {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.close {
  color: white !important;
}
</style>
