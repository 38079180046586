<template>
  <v-stories
    :key="storyRouteId"
    :story="story"
    :from-end="isFromEnd"
    @nextStory="onNextStory"
    @previousStory="onPreviousStory"
  />
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VStories from '@layouts/stories/VStories.vue'

export default Vue.extend({
  name: 'Stories',
  components: { VStories },
  data() {
    return {
      isFromEnd: false,
    }
  },
  computed: {
    ...mapGetters('Stories', ['stories', 'storiesLength']),
    ...mapGetters('Locale', ['locale']),
    storyRouteId() {
      return Number(this.$route?.params?.id) ?? null
    },
    story() {
      return this.stories?.find(({ id }) => id === Number(this.storyRouteId)) ?? null
    },
    storyIndex() {
      return this.stories.indexOf(this.story)
    },
  },
  methods: {
    ...mapActions('Stories', ['getStories']),
    ...mapMutations('Page', ['setPageIsHeader', 'setPageIsFooter']),
    ...mapMutations('Stories', ['setStoriesIsOpen']),
    async onGetStories() {
      await this.getStories()
    },
    onPreviousStory() {
      if (this.storyIndex > 0) {
        this.isFromEnd = true
        this.$router.push({
          name: 'Stories',
          params: { id: this.stories[this.storyIndex - 1].id, locale: this.locale },
        })
      }
    },
    onNextStory() {
      if (this.storyIndex < this.stories.length - 1) {
        this.isFromEnd = false
        this.$router.push({
          name: 'Stories',
          params: { id: this.stories[this.storyIndex + 1].id, locale: this.locale },
        })
      } else {
        this.onStoriesEnd()
      }
    },
    onStoriesEnd() {
      this.$router.push({ name: 'Profile' })
    },
  },
  async created() {
    this.setStoriesIsOpen(true)
    this.setPageIsHeader(false)
    this.setPageIsFooter(false)
    if (!this.storiesLength) {
      await this.onGetStories()
    }
  },
  destroyed() {
    this.setStoriesIsOpen(false)
    this.setPageIsHeader(true)
    this.setPageIsFooter(true)
  },
})
</script>

<style lang="scss" module></style>
