// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VStoriesHeader_container_Ah7lC{display:grid;grid-gap:9px;grid-template-columns:36px 1fr 36px 36px;align-items:center;padding:0 12px;color:#fff}.VStoriesHeader_container_Ah7lC.VStoriesHeader_noMenu_1FCEU{grid-template-columns:36px 1fr 36px}.VStoriesHeader_name_MUWHW{font-size:15px}.VStoriesHeader_name_MUWHW,.VStoriesHeader_views_2ZVgU{font-weight:500}.VStoriesHeader_views_2ZVgU{display:grid;grid-auto-flow:column;grid-gap:6px;align-items:center;font-size:12px;line-height:18px}.VStoriesHeader_close_21WyF{color:#fff!important}", ""]);
// Exports
exports.locals = {
	"container": "VStoriesHeader_container_Ah7lC",
	"noMenu": "VStoriesHeader_noMenu_1FCEU",
	"name": "VStoriesHeader_name_MUWHW",
	"views": "VStoriesHeader_views_2ZVgU",
	"close": "VStoriesHeader_close_21WyF"
};
module.exports = exports;
