<template>
  <v-modal
    :title="title"
    :color="color"
    :class-name="`${$style.modal} ${title && $style.modalWithTitle}`"
    :is-visible="isVisible"
    :no-scrollbar="noScrollbar"
    :is-confirm="isConfirm"
    :no-validation="noValidation"
    :is-loading="isLoading"
    @update:is-visible="$emit('update:is-visible', $event)"
    @close="$emit('close')"
  >
    <ul :class="$style.list">
      <li
        v-for="(item, index) in listVisible"
        :key="`list-modal-${index}`"
        :class="$style.item"
        @click="onClick(item)"
      >
        {{ item.value }}
      </li>
    </ul>
    <template #accept>
      <slot name="accept" />
    </template>
    <template #cancel>
      <slot name="cancel" />
    </template>
  </v-modal>
</template>
<script>
import Vue from 'vue'

import VModal from '@modals/VModal.vue'

export default Vue.extend({
  name: 'ListModal',
  components: { VModal },
  props: {
    title: String,
    color: String,
    isVisible: {
      require: true,
      type: Boolean,
    },
    list: {
      default: () => [],
      type: Array,
    },
    noScrollbar: Boolean,
    noValidation: Boolean,
    isConfirm: Boolean,
    isLoading: Boolean,
  },
  computed: {
    listVisible() {
      return this.list.filter(({ hidden }) => hidden !== true)
    },
  },
  methods: {
    onClick(element) {
      this.$emit('update:is-visible', false)
      this.$emit('click', element)
    },
  },
})
</script>
<style lang="scss" module>
.modal {
  padding: 0;

  &WithTitle {
    padding: 15px 0 0 0;
  }
}

.list {
  display: grid;
  grid-auto-flow: row;
  min-width: 200px;
}

.item {
  padding: 14px;
  color: $secondary;
  font-weight: 400;
  border-bottom: 2px solid $secondary;
  cursor: pointer;
  transition: opacity $transition, font-weight $transition;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    border: 0;
  }
}
</style>
