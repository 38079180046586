// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VStoriesTimeline_timeline_362Fq{display:grid;grid-auto-columns:1fr;grid-auto-flow:column;grid-gap:3px;padding:10px}.VStoriesTimeline_item_XbJxK{width:100%;background:hsla(0,0%,100%,.5)}.VStoriesTimeline_item_XbJxK,.VStoriesTimeline_progress_31ZbD{height:2px;border-radius:2px}.VStoriesTimeline_progress_31ZbD{width:0;background:#fff}", ""]);
// Exports
exports.locals = {
	"timeline": "VStoriesTimeline_timeline_362Fq",
	"item": "VStoriesTimeline_item_XbJxK",
	"progress": "VStoriesTimeline_progress_31ZbD"
};
module.exports = exports;
