// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ListModal_modal_34E94{padding:0}.ListModal_modalWithTitle_2YPVJ{padding:15px 0 0 0}.ListModal_list_3uUlZ{display:grid;grid-auto-flow:row;min-width:200px}.ListModal_item_3HynX{padding:14px;color:var(--supster-secondary);font-weight:400;border-bottom:2px solid var(--supster-secondary);cursor:pointer;transition:opacity .3s,font-weight .3s}.ListModal_item_3HynX:hover{opacity:.7}.ListModal_item_3HynX:last-child{border:0}", ""]);
// Exports
exports.locals = {
	"modal": "ListModal_modal_34E94",
	"modalWithTitle": "ListModal_modalWithTitle_2YPVJ",
	"list": "ListModal_list_3uUlZ",
	"item": "ListModal_item_3HynX"
};
module.exports = exports;
